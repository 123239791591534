import React from "react";
import "../styles/CreateAccount.css";
import CreateAccountModal from "../Components/CreateAccountModal";
const CreateAccount = () => {
  return (
    <div className="create-account-page">
      <CreateAccountModal visible={true} />
    </div>
  );
};

export default CreateAccount;
