import React, { createContext, useEffect, useState, useReducer } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TablePortions from "./routes/TablePortions";
import amplitude from "amplitude-js";
import RecipeList from "./routes/RecipeList";
import Page from "./Components/Page";
import CreateAccount from "./routes/CreateAccount";
import { AMP_KEY_PROD } from "./utils/analytics";
import DIET_GROUPS from "./constants/DietGroups";

export const AppContext = createContext();

const portionsReducer = (state, action) => {
  switch (action.type) {
    case "set_portions":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
};

const activeFoosReducer = (state, action) => {
  switch (action.type) {
    case "set_active_food":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
};

const portionsInitialState = {
  PortionVegetables: 0,
  Portionfruits: 1,
  PortionCerealesSG: 0,
  PortionCerealesCG: 0,
  PortionLegumes: 0,
  PortionAOA_MBAG: 0,
  PortionAOA_BAG: 0,
  PortionAOA_MAG: 0,
  PortionAOA_AG: 0,
  PortionMilkDes: 1,
  PortionMilkSemi: 0,
  PortionMilk: 0,
  PortionMilkWithSugar: 0,
  PortionFatWithoutProtein: 0,
  PortionFatWithProtein: 0,
  PortionSugarWithoutFat: 0,
  PortionSugarWithFat: 0,
};

const activeFoodsInitialState = {
  vegetables: false,
  fruits: true,
  cerealesSG: false,
  cerealesCG: false,
  legumes: false,
  AOA_MBAG: false,
  AOA_BAG: false,
  AOA_MAG: false,
  AOA_AG: false,
  milkDes: true,
  milkSemi: false,
  milk: false,
  milkWithSugar: false,
  fatWithoutProtein: false,
  fatWithProtein: false,
  sugarWithoutFat: false,
  sugarWithFat: false,
};

const App = () => {
  const [activeFood, dispatchActiveFood] = useReducer(
    activeFoosReducer,
    activeFoodsInitialState
  );
  const [portions, dispatchPortions] = useReducer(
    portionsReducer,
    portionsInitialState
  );
  const [isMobile, setIsMobile] = useState(false);
  const {
    vegetables,
    fruits,
    cerealesSG,
    cerealesCG,
    legumes,
    AOA_MBAG,
    AOA_BAG,
    AOA_MAG,
    AOA_AG,
    milkDes,
    milkSemi,
    milk,
    milkWithSugar,
    fatWithoutProtein,
    fatWithProtein,
    sugarWithoutFat,
    sugarWithFat,
  } = activeFood;
  const {
    PortionVegetables,
    Portionfruits,
    PortionCerealesSG,
    PortionAOA_BAG,
    PortionAOA_AG,
    PortionAOA_MAG,
    PortionCerealesCG,
    PortionLegumes,
    PortionAOA_MBAG,
    PortionMilkDes,
    PortionMilkSemi,
    PortionMilk,
    PortionMilkWithSugar,
    PortionFatWithProtein,
    PortionFatWithoutProtein,
    PortionSugarWithoutFat,
    PortionSugarWithFat,
  } = portions;
  const [totalKcal, setTotalKcal] = useState(370);
  const [showBannerDesktop, setShowBannerDesktop] = useState(false);

  useEffect(() => {
    const isMobileDevice = mobileCheck();
    setIsMobile(isMobileDevice);
    amplitude.getInstance().init(AMP_KEY_PROD);
  }, []);

  useEffect(() => {
    isMobile ? setShowBannerDesktop(true) : setShowBannerDesktop(false);
  }, [isMobile]);

  useEffect(() => {
    let calories = 0;
    if (vegetables && PortionVegetables > 0)
      calories += DIET_GROUPS.vegetables.kcal * PortionVegetables;
    if (fruits && Portionfruits > 0)
      calories += DIET_GROUPS.fruits.kcal * Portionfruits;
    if (cerealesSG && PortionCerealesSG > 0)
      calories += DIET_GROUPS.cerealsNoFat.kcal * PortionCerealesSG;
    if (cerealesCG && PortionCerealesCG > 0)
      calories += DIET_GROUPS.cerealsWithFat.kcal * PortionCerealesCG;
    if (legumes && PortionLegumes > 0)
      calories += DIET_GROUPS.legumes.kcal * PortionLegumes;
    if (AOA_MBAG && PortionAOA_MBAG > 0)
      calories += DIET_GROUPS.aoambag.kcal * PortionAOA_MBAG;
    if (AOA_BAG && PortionAOA_BAG > 0)
      calories += DIET_GROUPS.aoabag.kcal * PortionAOA_BAG;
    if (AOA_MAG && PortionAOA_MAG > 0)
      calories += DIET_GROUPS.aoamag.kcal * PortionAOA_MAG;
    if (AOA_AG && PortionAOA_AG > 0)
      calories += DIET_GROUPS.aoaaag.kcal * PortionAOA_AG;
    if (milkDes && PortionMilkDes > 0)
      calories += DIET_GROUPS.milkDes.kcal * PortionMilkDes;
    if (milkSemi && PortionMilkSemi > 0)
      calories += DIET_GROUPS.milkSemi.kcal * PortionMilkSemi;
    if (milk && PortionMilk > 0)
      calories += DIET_GROUPS.milk.kcal * PortionMilk;
    if (milkWithSugar && PortionMilkWithSugar > 0)
      calories += DIET_GROUPS.milkWithSugar.kcal * PortionMilkWithSugar;
    if (fatWithoutProtein && PortionFatWithoutProtein > 0)
      calories += DIET_GROUPS.fatWithoutProtein.kcal * PortionFatWithoutProtein;
    if (fatWithProtein && PortionFatWithProtein > 0)
      calories += DIET_GROUPS.fatWithProtein.kcal * PortionFatWithProtein;
    if (sugarWithoutFat && PortionSugarWithoutFat > 0)
      calories += DIET_GROUPS.sugarWithoutFat.kcal * PortionSugarWithoutFat;
    if (sugarWithFat && PortionSugarWithFat > 0)
      calories += DIET_GROUPS.sugarWithFat.kcal * PortionSugarWithFat;
    setTotalKcal(calories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portions, activeFood]);

  const mobileCheck = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const setPortions = (key, value) => {
    dispatchPortions({
      type: "set_portions",
      payload: {
        key,
        value,
      },
    });
  };

  const setActiveFood = (key, value) => {
    dispatchActiveFood({
      type: "set_active_food",
      payload: {
        key,
        value,
      },
    });
  };

  return (
    <AppContext.Provider
      value={{
        isMobile,
        activeFoodState: activeFood,
        portionsState: portions,
        portions: {
          vegetables: PortionVegetables * vegetables,
          fruits: Portionfruits * fruits,
          cerealesSG: PortionCerealesSG * cerealesSG,
          cerealesCG: PortionCerealesCG * cerealesCG,
          legumes: PortionLegumes * legumes,
          AOA_MBAG: PortionAOA_MBAG * AOA_MBAG,
          AOA_BAG: PortionAOA_BAG * AOA_BAG,
          AOA_MAG: PortionAOA_MAG * AOA_MAG,
          AOA_AG: PortionAOA_AG * AOA_AG,
          milkDes: PortionMilkDes * milkDes,
          milkSemi: PortionMilkSemi * milkSemi,
          milk: PortionMilk * milk,
          milkWithSugar: PortionMilkWithSugar * milkWithSugar,
          fatWithoutProtein: PortionFatWithoutProtein * fatWithoutProtein,
          fatWithProtein: PortionFatWithProtein * fatWithProtein,
          sugarWithoutFat: PortionSugarWithoutFat * sugarWithoutFat,
          sugarWithFat: PortionSugarWithFat * sugarWithFat,
        },
        totalKcal,
        showBannerDesktop,
        setShowBannerDesktop: (value) => setShowBannerDesktop(value),
        setPortions,
        setActiveFood,
      }}
    >
      <Router>
        <Switch>
          <Route exact path="/create-account" component={CreateAccount} />
          <Page>
            <Route exact index path="/" component={TablePortions} />
            <Route exact path="/recipes" component={RecipeList} />
          </Page>
        </Switch>
      </Router>
    </AppContext.Provider>
  );
};

export default App;
