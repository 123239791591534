// Group values for 1 portion
const DIET_GROUPS = {
  vegetables: {
    name: 'Verduras',
    altName: 'Common.vegetable',
    kcal: 25,
    protein: 2,
    lipids: 0,
    carbs: 4
  },
  fruits: {
    name: 'Frutas',
    altName: 'Common.fruit',
    kcal: 60,
    protein: 0,
    lipids: 0,
    carbs: 15
  },
  cerealsNoFat: {
    name: 'Cereales sin grasa',
    altName: 'Common.cereal_without_fat',
    kcal: 70,
    protein: 2,
    lipids: 0,
    carbs: 15
  },
  cerealsWithFat: {
    name: 'Cereales con grasa',
    altName: 'Common.cereal_with_fat',
    kcal: 115,
    protein: 2,
    lipids: 5,
    carbs: 15
  },
  legumes: {
    name: 'Leguminosas',
    altName: 'Common.legumes',
    kcal: 120,
    protein: 8,
    lipids: 1,
    carbs: 20
  },
  aoambag: {
    name: 'Alimentos de origen animal muy bajo aporte de grasa',
    altName: 'Common.aoambag',
    kcal: 40,
    protein: 7,
    lipids: 1,
    carbs: 0
  },
  aoabag: {
    name: 'Alimentos de origen animal bajo aporte de grasa',
    altName: 'Common.aoabag',
    kcal: 55,
    protein: 7,
    lipids: 3,
    carbs: 0
  },
  aoamag: {
    name: 'Alimentos de origen animal moderado aporte de grasa',
    altName: 'Common.aoamag',
    kcal: 75,
    protein: 7,
    lipids: 5,
    carbs: 0
  },
  aoaaag: {
    name: 'Alimentos de origen animal alto aporte de grasa',
    altName: 'Common.aoaaag',
    kcal: 100,
    protein: 7,
    lipids: 8,
    carbs: 0
  },
  milkDes: {
    name: 'Leche descremada',
    altName: 'Common.skim_milk',
    kcal: 95,
    protein: 9,
    lipids: 2,
    carbs: 12
  },
  milkSemi: {
    name: 'Leche semidescremada',
    altName: 'Common.semi_skim_milk',
    kcal: 110,
    protein: 9,
    lipids: 4,
    carbs: 12
  },
  milk: {
    name: 'Leche entera',
    altName: 'Common.whole_milk',
    kcal: 150,
    protein: 9,
    lipids: 8,
    carbs: 12
  },
  milkWithSugar: {
    name: 'Leche con azúcar',
    altName: 'Common.milk_with_sugar',
    kcal: 200,
    protein: 8,
    lipids: 5,
    carbs: 30
  },
  fatWithoutProtein: {
    name: 'Grasas sin proteína',
    altName: 'Common.fat_without_protein',
    kcal: 45,
    protein: 0,
    lipids: 5,
    carbs: 0
  },
  fatWithProtein: {
    name: 'Grasas con proteína',
    altName: 'Common.fat_with_protein',
    kcal: 70,
    protein: 3,
    lipids: 5,
    carbs: 3
  },
  sugarWithoutFat: {
    name: 'Azúcares sin grasa',
    altName: 'Common.sugar_without_fat',
    kcal: 40,
    protein: 0,
    lipids: 0,
    carbs: 10
  },
  sugarWithFat: {
    name: 'Azúcares con grasa',
    altName: 'Common.sugar_with_fat',
    kcal: 85,
    protein: 0,
    lipids: 5,
    carbs: 10
  }
};

export default DIET_GROUPS;
