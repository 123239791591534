import React, { useState } from "react";
import {
  Button,
  InputNumber,
  Modal,
  Checkbox,
  Alert,
  Typography,
  Row,
  Col,
} from "antd";
import {
  MinusOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import useQuery from "../hooks/useQueryParams";
import { labels, relations } from "../constants/labels";
import { logEvent } from "../utils/analytics";

//styles
import "antd/dist/antd.css";
import "../styles/TablePortions.css";
import useAppContext from "../hooks/useAppContext";

const TablePortions = () => {
  const query = useQuery();
  const isLogged = query.get("logged");
  const {
    totalKcal,
    portionsState,
    activeFoodState,
    setPortions,
    setActiveFood,
  } = useAppContext();
  const [modalVisible, setmodalVisible] = useState(false);

  return (
    <div className="container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 10,
        }}
      >
        <Typography.Title
          style={{ fontWeight: 300, marginBottom: -5 }}
        >
          Generador de recetas
        </Typography.Title>
        <Typography.Text
          style={{ textAlign: "center", fontSize: 14, color: "#d3d3d3" }}
        >
          Crea recetas con tus porciones
        </Typography.Text>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th style={{ textAlign: "left" }}>Grupo</th>
            <th>Porciones</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(portionsState).map((key) => {
            if (!activeFoodState[relations[key]]) {
              return null;
            }
            return (
              <tr className="table__row" key={key}>
                <td>{labels[key]}</td>
                <td className="table__input">
                  <InputNumber
                    className="input__portions"
                    min={0}
                    max={100}
                    value={portionsState[key]}
                    addonBefore={
                      <MinusOutlined
                        className="icon-control"
                        onClick={() => {
                          if (portionsState[key] <= 0.5) {
                            setPortions(key, 0);
                          } else {
                            setPortions(key, portionsState[key] - 0.5);
                          }
                        }}
                      />
                    }
                    addonAfter={
                      <PlusOutlined
                        className="icon-control"
                        onClick={() => {
                          setPortions(key, portionsState[key] + 0.5);
                        }}
                      />
                    }
                    step={0.5}
                    onChange={(value) => setPortions(key, value)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="button__container">
        <Button
          block
          type="dashed"
          icon={<PlusCircleOutlined />}
          style={{ borderRadius: 10 }}
          onClick={(e) => {
            e.preventDefault();
            setmodalVisible(true);
          }}
        >
          AGREGAR GRUPO
        </Button>
      </div>
      {totalKcal > 0 ? (
        <Link
          to={{
            pathname: `/recipes`,
            search: isLogged ? `?logged=${isLogged}` : "",
          }}
          className="button__container--generate"
        >
          <Button
            type="primary"
            onClick={() => {
              logEvent("mobile_generate_recipes", {
                type: isLogged,
                demo: isLogged === "demo",
              });
            }}
            className="button__custom "
          >
            <strong>
              Generar recetas de <strong>{totalKcal} Kcal</strong>
            </strong>
          </Button>
        </Link>
      ) : (
        <div className="button__container" style={{ marginTop: "15px" }}>
          <Alert
            style={{ width: "100%" }}
            message="Agrega tus porciones para crear recetas."
            type="info"
            showIcon
          />
        </div>
      )}

      <Modal
        title="AGREGAR GRUPO"
        visible={modalVisible}
        onCancel={() => setmodalVisible(false)}
        onOk={() => setmodalVisible(false)}
        footer={[
          <Button
            key="OK"
            type="primary"
            style={{ borderRadius: "5px" }}
            onClick={() => {
              setmodalVisible(false);
            }}
          >
            Listo
          </Button>,
        ]}
        centered={true}
      >
        <Row gutter={[15, 15]}>
          {Object.keys(activeFoodState).map((key) => {
            return (
              <Col md={8} xs={12} key={key}>
                <Checkbox
                  checked={activeFoodState[key]}
                  onChange={() => {
                    setActiveFood(key, !activeFoodState[key]);
                  }}
                >
                  {labels[key]}
                </Checkbox>
              </Col>
            );
          })}
        </Row>
      </Modal>
    </div>
  );
};

export default TablePortions;
