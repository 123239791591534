import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import logo from "../assets/Avena_logo.png";
import "../styles/Header.css";
import useAppContext from "../hooks/useAppContext";

const Header = ({ isLogged }) => {
  const { isMobile } = useAppContext();

  return (
    <Layout.Header
      className="site-layout-background"
      style={{ padding: 0, height: 70 }}
    >
      <div
        className={"header-container"}
        style={{
          justifyContent: !isMobile
            ? "flex-start"
            : isLogged === "demo"
            ? "space-between"
            : "flex-end",
        }}
      >
        {isMobile && isLogged === "demo" ? (
          <Link
            to={{
              pathname: "https://avena.io/precios/",
            }}
            target="_parent"
          >
            <LeftOutlined style={{ fontSize: 24, color: "black" }} />
          </Link>
        ) : null}
        <Link
          to={{ pathname: "/", search: isLogged ? `?logged=${isLogged}` : "" }}
          className="header"
        >
          <img src={logo} alt="Logo" className="logo" />
          <h1 className="title">Avena.io</h1>
        </Link>
      </div>
    </Layout.Header>
  );
};

export default Header;
