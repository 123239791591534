const API_URL = "https://avena-bot.appspot.com";

export const createFreeAccountApiMethod = async ({ name, email, mobile }) => {
  const endpoint = API_URL + "/_ah/api/coach/v1/free";
  const config = {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      email,
      name,
      mobile,
    }),
  };
  const response = await fetch(endpoint, config).catch(() => {
    return Promise.reject(`API call failed ${endpoint}`);
  });
  if (response.ok) {
    return await response.json().catch((e) => {
      //assume that the response is 204 No Content
      return Promise.reject(`empty response ${e}`);
    });
  } else {
    return handleErrorResponse(response);
  }
};

async function handleErrorResponse(response) {
  try {
    const { error } = await response.json();
    const responseObj = {
      url: response.url,
      status: response.status,
      error: {
        code: error.code,
        message: error.message,
        data: null,
      },
    };
    return Promise.reject(responseObj);
  } catch (error) {
    const data = await response.text();
    const responseObj = {
      url: response.url,
      status: response.status,
      error: {
        code: null,
        message: `Could not parse response to JSON. ${error}`,
        data,
      },
    };
    return Promise.reject(responseObj);
  }
}
