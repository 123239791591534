import React, { useState } from "react";
import { Button, Modal, Row, Col, Typography } from "antd";
import {
  CloseCircleOutlined,
  EditOutlined,
  FileTextOutlined,
  FileAddOutlined,
  LaptopOutlined,
} from "@ant-design/icons";
import "../styles/RecipeCard.css";
import { Link } from "react-router-dom";
import { logEvent } from "../utils/analytics";

const footerButtons = [
  {
    icon: <FileTextOutlined style={{ fontSize: 16, color: "black" }} />,
    label: "Descargar PDF",
  },
  {
    icon: <FileAddOutlined style={{ fontSize: 16, color: "black" }} />,
    label: "Usar en menú",
  },
  {
    icon: <EditOutlined style={{ fontSize: 16, color: "black" }} />,
    label: "Editar receta",
  },
];

const RecipeCard = ({ recipe, isLogged, isMobile }) => {
  const [viewRecipe, setViewRecipe] = useState(false);
  const _handleClick = () => {
    logEvent("mobile_view_recipe", {
      type: isLogged,
      demo: isLogged === "demo",
    });
    setViewRecipe(true);
  };
  return (
    <>
      <div
        className="card-image"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0,0,0,0)), url(${recipe.image})`,
        }}
      >
        <Row>
          <Col span={18}>
            <span className="card-text">{recipe?.title}</span>
          </Col>
          <Col span={18}>
            <Button
              type="default"
              className="card__button"
              onClick={() => {
                _handleClick();
              }}
            >
              Ver receta
            </Button>
          </Col>
        </Row>
      </div>
      <Modal
        title={<></>}
        wrapClassName="recipe-modal"
        visible={viewRecipe}
        style={isMobile ? { top: 20 } : {}}
        onOk={() => {
          setViewRecipe(false);
        }}
        onCancel={() => {
          setViewRecipe(false);
        }}
        closeIcon={
          <CloseCircleOutlined
            style={{ color: "rgb(4, 50, 213)", fontWeight: "bold", fontSize: 22 }}
          />
        }
        footer={null}
      >
        <div>
          <div className="modal__container">
            <div
              className="modal__image"
              style={{ backgroundImage: `url(${recipe.image})` }}
            />

            <h3 className="title-card-modal">{recipe.title}</h3>
            <Typography.Text style={{ fontWeight: "500", marginBottom: 5 }}>
              Ingredientes
            </Typography.Text>
            {recipe?.ingredients.map((ingredient) => {
              return (
                <Typography.Text
                  key={ingredient.food}
                  style={{ marginBottom: 8 }}
                >
                  {ingredient?.portion ? (
                    <span>
                      {ingredient.portion} {ingredient.unit}
                      {ingredient.equivalenceGr
                        ? `(${ingredient.equivalenceGr} gramos)`
                        : ""}{" "}
                      de {ingredient.food} - {ingredient.group}
                    </span>
                  ) : (
                    `${ingredient.food} - Complemento`
                  )}
                </Typography.Text>
              );
            })}
            <Typography.Text
              style={{ fontWeight: "500", marginBottom: 5, marginTop: 10 }}
            >
              Preparación
            </Typography.Text>
            {recipe?.preparation.map((step, index) => {
              return (
                <Typography.Text
                  style={{ marginBottom: 8 }}
                  key={recipe.id + index + 1}
                >
                  {index + 1}.{step}
                </Typography.Text>
              );
            })}
          </div>
          <div className="row-footer">
            {isLogged === "app-coach" || isLogged === "web-coach" ? (
              <div
                className="footer-modal-item"
                style={{ padding: "10px 30px" }}
              >
                <LaptopOutlined style={{ fontSize: 18 }} />
                <Typography.Text style={{ marginTop: 5 }}>
                  Explora desde tu computadora
                </Typography.Text>
              </div>
            ) : (
              footerButtons.map((item) => {
                return (
                  <Link 
                    key={item.label}
                    to={{
                      pathname: "https://avena.io/precios/",
                    }}
                    target="_parent">
                    <div className="footer-modal-item">
                      {item.icon}
                      <Typography.Text style={{ marginTop: 5, fontSize: 12 }}>
                        {item.label}
                      </Typography.Text>
                    </div>
                  </Link>
                );
              })
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RecipeCard;
