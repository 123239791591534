import { useState, useEffect } from "react";
import { message } from "antd";
import useAppContext from "./useAppContext";
const API_URL = "https://api.avena.io/_ah/api/dietitianPlan/v1/recipes/open?";

const useRecipes = () => {
  const { portions } = useAppContext();
  const [recipes, setRecipes] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const params = {
    dietitianPlan: {
      version: "V2",
      meals: [
        {
          index: 0,
          name: "demo",
          portions: {
            vegetables: portions.vegetables,
            fruits: portions.fruits,
            cerealsNoFat: portions.cerealesSG,
            cerealsWithFat: portions.cerealesCG,
            legumes: portions.legumes,
            aoambag: portions.AOA_MBAG,
            aoabag: portions.AOA_BAG,
            aoamag: portions.AOA_MAG,
            aoaaag: portions.AOA_AG,
            milkDes: portions.milkDes,
            milkSemi: portions.milkSemi,
            milk: portions.milk,
            milkWithSugar: portions.milkWithSugar,
            fatWithoutProtein: portions.fatWithoutProtein,
            fatWithProtein: portions.fatWithProtein,
            sugarWithoutFat: portions.sugarWithoutFat,
            sugarWithFat: portions.sugarWithFat,
          },
        },
      ],
      splitSubtypes: true,
    },
  };

  useEffect(() => {
    if (hasMore) {
      _fetchItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const _fetchItems = () => {
    setIsFetching(true);
    fetch(`${API_URL}page=${pageNumber}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((response) => {
        const items = response?.items?.[0]?.recipes;
        if (pageNumber === 1) {
          setRecipes(items);
        } else {
          setRecipes((s) => s.concat(items));
        }
        setHasMore(!items?.length < 8);
      })
      .catch(() => {
        message.error("Ocurrió un error, por favor intentar de nuevo", 3);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  return {
    recipes,
    isFetching,
    hasMore,
    _fetchItems,
    showModal,
    _nextPage: (logged) => {
      if (logged) {
        setPageNumber((s) => s + 1);
      } else if (pageNumber === 1) {
        setHasMore(false);
        setShowModal(true);
      } else {
        setPageNumber((s) => s + 1);
      }
    },
    _hideModal: () => setShowModal(false),
  };
};

export default useRecipes;
