import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import { logEvent } from "../utils/analytics";
import "../styles/SegmentedControl.css";

const SegmentedControl = ({ initialValue, isLogged }) => {
  return (
    <Row className="segment-container" justify="center">
      <Row className="segment-controller">
        <Col
          className="background-transition control"
          span={14}
          style={{
            borderRight: initialValue === "/" ? "1px solid #000" : null,
            background: initialValue === "/" ? "#0432D5" : "#d2ddfa",
            color: initialValue === "/" ? "white" : "#0432d5",
          }}
        >
          <Link
            to={{
              pathname: "/",
              search: isLogged ? `?logged=${isLogged}` : "",
            }}
            className="control-link"
            style={{
              color: initialValue === "/" ? "white" : "#0432d5",
            }}
          >
            Porciones
          </Link>
        </Col>

        <Col
          span={10}
          className="background-transition control"
          style={{
            borderLeft: initialValue === "/recipes" ? "1px solid #000" : null,
            background: initialValue === "/recipes" ? "#0432D5" : "#d2ddfa",
            color: initialValue === "/recipes" ? "white" : "#0432d5",
          }}
          onClick={() => {
            logEvent("mobile_generate_recipes", {
              type: isLogged,
              demo: isLogged === "demo",
            });
          }}
        >
          <Link
            to={{
              pathname: "/recipes",
              search: isLogged ? `?logged=${isLogged}` : "",
            }}
            className="control-link"
            style={{
              color: initialValue === "/recipes" ? "white" : "#0432d5",
            }}
          >
            Recetas
          </Link>
        </Col>
      </Row>
    </Row>
  );
};

export default SegmentedControl;
