import DIET_GROUPS from "./DietGroups";
export const labels = {
  vegetables: "Verduras",
  fruits: DIET_GROUPS.fruits.name,
  cerealesSG: "Cereales sin grasa",
  cerealesCG: "Cereales con grasa",
  legumes: "Leguminosas",
  milkDes: "Leche descremada",
  milkSemi: "Leche semidescremada",
  milk: "Leche entera",
  milkWithSugar: "Leche con azúcar",
  fatWithoutProtein: "Grasas sin proteína",
  fatWithProtein: "Grasas con proteína",
  sugarWithoutFat: "Azúcares sin grasa",
  sugarWithFat: "Azúcares con grasa",
  AOA_MBAG: "Alimentos de origen animal muy bajo aporte de grasa",
  AOA_BAG: "Alimentos de origen animal bajo aporte de grasa",
  AOA_MAG: "Alimentos de origen animal moderado aporte de grasa",
  AOA_AG: "Alimentos de origen animal alto aporte de grasa",
  PortionVegetables: "Verduras",
  Portionfruits: DIET_GROUPS.fruits.name,
  PortionCerealesSG: "Cereales sin grasa",
  PortionCerealesCG: "Cereales con grasa",
  PortionLegumes: "Leguminosas",
  PortionAOA_MBAG: "Alimentos de origen animal muy bajo aporte de grasa",
  PortionAOA_BAG: "Alimentos de origen animal bajo aporte de grasa",
  PortionAOA_MAG: "Alimentos de origen animal moderado aporte de grasa",
  PortionAOA_AG: "Alimentos de origen animal alto aporte de grasa",
  PortionMilkDes: "Leche descremada",
  PortionMilkSemi: "Leche semidescremada",
  PortionMilk: "Leche entera",
  PortionMilkWithSugar: "Leche con azúcar",
  PortionFatWithoutProtein: "Grasas sin proteína",
  PortionFatWithProtein: "Grasas con proteína",
  PortionSugarWithoutFat: "Azúcares sin grasa",
  PortionSugarWithFat: "Azúcares con grasa",
};

export const relations = {
  PortionVegetables: "vegetables",
  Portionfruits: "fruits",
  PortionCerealesSG: "cerealesSG",
  PortionCerealesCG: "cerealesCG",
  PortionLegumes: "legumes",
  PortionAOA_MBAG: "AOA_MBAG",
  PortionAOA_BAG: "AOA_BAG",
  PortionAOA_MAG: "AOA_MAG",
  PortionAOA_AG: "AOA_AG",
  PortionMilkDes: "milkDes",
  PortionMilkSemi: "milkSemi",
  PortionMilk: "milk",
  PortionMilkWithSugar: "milkWithSugar",
  PortionFatWithoutProtein: "fatWithoutProtein",
  PortionFatWithProtein: "fatWithProtein",
  PortionSugarWithoutFat: "sugarWithoutFat",
  PortionSugarWithFat: "sugarWithFat",
};
