import React from "react";
import { Layout, Row } from "antd";

import Header from "./Header";
import { BannerDesktop, BannerPremium } from "./Banners";
import SegmentedControl from "./SegmentedControl";
import useAppContext from "../hooks/useAppContext";

const Page = ({ children }) => {
  const { showBannerDesktop, setShowBannerDesktop, isMobile } = useAppContext();
  const location = window.location.pathname;
  const margintop = { marginTop: "25px", width: "100%" };
  let search = window.location.search;
  search = search.replace("?", "");
  const param = search.split("=");
  let logged = false;
  if (param?.[0] === "logged") {
    logged = param?.[1];
  }

  const hideHeaderAndFooter = isMobile && logged === "app-coach";
  return (
    <Layout className="page  site-layout-background">
      {showBannerDesktop && (
        <BannerDesktop onClose={() => setShowBannerDesktop(false)} />
      )}
      {hideHeaderAndFooter ? null : <Header isLogged={logged} />}
      <Row style={hideHeaderAndFooter ? margintop : { width: "100%" }}>
        <SegmentedControl initialValue={location} isLogged={logged} />
      </Row>
      <div className="page-layout">{children}</div>
      {hideHeaderAndFooter ? null : (
        <Layout.Footer style={{ textAlign: "center" }}>
          {logged !== "web-coach" ? (
            <BannerPremium />
          ) : (
            "Built with ❤️ by Avena©"
          )}
        </Layout.Footer>
      )}
    </Layout>
  );
};

export default Page;
