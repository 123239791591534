import React from "react";
import RecipeCard from "../Components/RecipeCard";
import { Spin, Alert, Modal, Button, Row, Col } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import useQueryParams from "../hooks/useQueryParams";
import image from "../assets/images/Recipe.png";
import "../styles/RecipeList.css";

import useRecipes from "../hooks/useRecipes";
import useAppContext from "../hooks/useAppContext";

//const linkAvenaPremium = "https://bit.ly/CalculadoraAvena";
const linkAvenaPremium = "https://avena.io/precios/";

const RecipeList = () => {
  const { isMobile } = useAppContext();
  const query = useQueryParams();
  const { totalKcal } = useAppContext();
  const logged = query.get("logged");
  const { recipes, isFetching, hasMore, showModal, _nextPage, _hideModal } =
    useRecipes();

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <span style={{ fontSize: 12 }}>
            {isFetching ? "Cargando..." : "Resultados de"}
          </span>
          <h3
            level={3}
            style={{
              fontWeight: 400,
              fontSize: 20,
              lineHeight: 1,
            }}
          >
            Recetas de {totalKcal} KCAL
          </h3>
        </Col>
        <Col span={24}>
          <Row gutter={[15, 15]}>
            {recipes.map((recipe) => (
              <Col key={recipe.id} md={6} xs={12}>
                <RecipeCard
                  className="card__container"
                  recipe={recipe}
                  isLogged={logged}
                  isMobile={isMobile}
                />
              </Col>
            ))}
          </Row>

          {!isFetching && recipes.length === 0 ? (
            <>
              <Alert
                message="No hay recetas para tu búsqueda. Son todas las recetas para las porciones elegidas."
                type="warning"
                showIcon
              />
              <Row justify="center" style={{ marginTop: 20 }}>
                <Link to={"/"}>
                  <Button icon={<ArrowLeftOutlined />} type="dashed">
                    Volver a seleccionar porciones.
                  </Button>
                </Link>
              </Row>
            </>
          ) : null}
        </Col>
        <Col span={24}>
          {isFetching ? (
            <Row justify="center">
              <Spin style={{ margin: 30 }} size="large" />
            </Row>
          ) : (
            <>
              {recipes.length === 0 ? null : (
                <Row justify="center">
                  <Col md={12} xs={24}>
                    {hasMore ? (
                      <Button
                        type="primary"
                        className="button__custom"
                        block
                        onClick={() => (hasMore ? _nextPage(logged) : null)}
                      >
                        <strong>Ver más</strong>
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              )}
            </>
          )}
        </Col>
      </Row>
      <Modal
        title={null}
        footer={null}
        visible={showModal}
        closable={false}
        width="925px"
        onCancel={_hideModal}
        bodyStyle={{ display: "flex", padding: "0px" }}
      >
        <div className="modalRestriction__container--info">
          <h3>Recetas y Menús</h3>
          <p>
            Si deseas tener acceso a más de <strong>10 mil recetas</strong>{" "}
            accede a tu panel en Avena y descubre todas las recetas y distribuciones.
          </p>
          <div className="modalRestriction__container--benefits">
            <p>
              -Veganas.<br/>
              -Vegetarianas.<br/>
              -Menús (Renal, Diabetico, Hipotiroidismo).<br/>
              -Cientos de recetas exclusivas y más...
            </p>
          </div>
          <div className="modalRestriction__container--button">
            <a href={linkAvenaPremium}>
              <Button
                style={{
                  borderRadius: "5px",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
                type="primary"
              >
                <strong>Crear cuenta</strong>
              </Button>
            </a>
          </div>
        </div>
        <div className="modalRestriction__container--image">
          <h1>
            <strong>Alcanzaste el limite de recetas.</strong>
          </h1>
          <img
            src={image}
            alt="imageRecipe"
            className="modalRestriction__image"
          />
          <div></div>
        </div>
      </Modal>
    </>
  );
};

export default RecipeList;
