import React from "react";
import { Row, Typography, Col, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { logEvent } from "../utils/analytics";
import "../styles/Banners.css";

const imageFooter = require("../assets/images/footer_image.png");
const BannerDesktop = ({ onClose }) => {
  return (
    <div className="background-banner-desktop">
      <p className="banner-text">
        Ingresa desde al panel desde tu computadora para tener la experiencia completa
      </p>
      <CloseOutlined
        style={{ fontWeight: "bold", fontSize: 16, cursor: "pointer" }}
        onClick={onClose}
      />
    </div>
  );
};

const BannerPremium = ({ isLogged }) => {
  const handleClick = () => {
    logEvent("mobile_banner_explore", {
      type: isLogged,
      demo: isLogged === "demo",
    });
  };

  return (
    <Row className="banner__container--gradient">
      <Col md={8} xs={0}>
        <img alt="Avena Salud" src={imageFooter} style={{ height: 80 }} />
      </Col>
      <Col></Col>
      <Col xs={15} md={8}>
        <Typography.Text className="text-banner-gradient">
          Agiliza tus consultas con +500 plantillas y +10,000 recetas listas para asignar
        </Typography.Text>
      </Col>
      <Col xs={9} md={8}>
        <Link
          to={{
            pathname: "https://avena.io/precios/",
          }}
          target="_parent"
        >
          <Button className="card__button" onClick={handleClick} type="default">
            Explorar
          </Button>
        </Link>
      </Col>
    </Row>
  );
};

export { BannerDesktop, BannerPremium };
