import { useContext } from "react";
import { AppContext } from "../App";

const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("App context must be used within App Provider");
  }
  return context;
};

export default useAppContext;
