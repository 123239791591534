import React, { useState } from "react";
import { Modal, Form, Button, Input, message } from "antd";
import useAppContext from "../hooks/useAppContext";
import { createFreeAccountApiMethod } from "../api/signup";
import amplitude from "amplitude-js";
import "../styles/CreateAccountModal.css";
import logo from "../assets/images/logo-create-account.png";

const CreateAccountModal = ({ visible = false }) => {
  const { isMobile } = useAppContext();
  const [loading, setLoading] = useState(false);
  const handleCreateAccount = (values) => {
    const { name, email } = values;
    const emailNormalized = email.toLowerCase().trim();
    setLoading(true);
    createFreeAccountApiMethod({
      email: emailNormalized,
      name,
      mobile: isMobile,
    })
      .then(() => {
        amplitude.getInstance().setUserId(emailNormalized);
        amplitude
          .getInstance()
          .logEvent("demo_user_created", { isMobile: false });
        //initializa amplitude-js with anonymous user data
        //https://www.docs.developers.amplitude.com/data/sdks/javascript/#logged-out-and-anonymous-users
        amplitude.getInstance().setUserId(null);
        amplitude.getInstance().regenerateDeviceId();
        window.open(
          `https://panel.avena.io/#/login?email=${emailNormalized}`,
          "_self"
        );
      })
      .catch((err) => {
        message.error(
          "Ocurrió un error al crear la cuenta, por favor intentar de nuevo",
          3
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      title={null}
      closable={false}
      maskClosable={false}
      className="create-account-modal"
      bodyStyle={{ padding: "28px" }}
    >
      <div className="create-account-modal__top-info">
        <img src={logo} alt="logo" />
        <h3>Prueba Avena Gratis</h3>
        <p>Sin tarjeta de crédito, miles de recetas, plantillas y más...</p>
      </div>
      <Form
        name="signUpForm"
        onFinish={handleCreateAccount}
        layout="vertical"
        className="create-account-modal__form"
      >
        <Form.Item name={"name"} label="Nombre completo:">
          <Input
            placeholder="Tu nombre completo"
            size="large"
            disabled={loading}
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="Ingresa tu correo electrónico:"
          rules={[
            { required: true, message: "Por favor ingresa tu correo" },
            {
              type: "email",
              message: "Ingresa un correo válido por favor",
              transform: (value) =>
                typeof value === "string" ? value.trim() : value,
            },
          ]}
        >
          <Input
            placeholder="tuemail@gmail.com"
            size="large"
            disabled={loading}
          />
        </Form.Item>
        <Button
          type="primary"
          size="large"
          className="btn-secondary btn-rounded"
          htmlType="submit"
          loading={loading}
          block
        >
          {loading ? "Creando cuenta..." : "Continuar"}
        </Button>
      </Form>
    </Modal>
  );
};

export default CreateAccountModal;
